import { store as AuthStore } from '@app/modules/Auth';
import { CoilCharacteristicsStore } from '@app/modules/Characteristics';

class RootStore {
  authStore: AuthStore;
  coilCharacteristicsStore: CoilCharacteristicsStore;

  constructor() {
    this.authStore = new AuthStore();
    this.coilCharacteristicsStore = new CoilCharacteristicsStore();
  }
}

export default RootStore;
