export default class StorageService {
  prefix: string;

  constructor(prefix: string) {
    this.prefix = prefix || '_';
  }

  _getKey(key: string) {
    return `${this.prefix}${key}`;
  }

  get(item: string) {
    return this.deserialize(localStorage.getItem(this._getKey(item)) || 'null');
  }

  set(item: string, data: any) {
    return localStorage.setItem(this._getKey(item), this.serialize(data));
  }

  remove(item: string) {
    return localStorage.removeItem(this._getKey(item));
  }

  serialize(data: any) {
    return JSON.stringify(data);
  }

  deserialize(str: string) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  }
}
