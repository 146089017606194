import React from 'react';
import { RouteInterface } from '@app/types/routes';
import { PATH } from '../../constants';
import { Page } from './pages';
import { Characteristics } from './components/Characteristics';

export default [
  {
    element: <Page />,
    name: 'Coil Characteristics',
    path: PATH.CHRACTERISTICS,
    children: []
  },
  {
    element: <Characteristics />,
    path: `${PATH.CHRACTERISTICS}/characteristics/:id`,
    name: 'Edit'
  }
] as RouteInterface[];
