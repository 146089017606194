/**
 * Http client based on axios and utilize Proxy to construct requests in handy way.
 * @example Usage example:
 * ```
 *  const apiServie = ApiService.createAxiosProxy({ baseURL: 'https://base.com/api' });
 *  fetch = apiService.getCountries()                   -> will call axiosInstance.request({ method: 'get', url: '/countries' });
 *  fetch = apiService.postCountry({name: 'Australia'}) -> will call axiosInstance.request({ method: 'post', url: '/countries', data: {name: 'Australia'} });
 *  fetch = apiService.getCountries(12)                 -> will call axiosInstance.request({ method: 'post', url: '/countries/12');
 *  fetch = apiService.get('/countries')                -> will work the same way as first example
 * ```
 * result of these call will be CancellableRequest - thenable object
 * so it can be awaited to get data directly from first call;
 * @example to get data from the CancellableRequest:
 * ```
 * const data = await fetch.request;
 * // OR await the first call as simple as:
 * const data = await apiService.getCountries();
 * ```
 * @example to cancel request:
 * ```
 * fetch.cancel('Request was cancelled due to ...');
 * ```
 *
 * Any unsupported method will throw error with description!
 */
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler
} from 'axios';
import { getBaseUrl } from './url';

interface CancellableRequest {
  request: Promise<any>;

  cancel: Canceler;

  then: (resolve?: () => {}, reject?: () => {}) => {};
}
const SUPPORTED_METHODS = [
  'get',
  'delete',
  'post',
  'put',
  'patch',
  'options',
  'head'
];
const STARTINGSLASH = /^\//;
const TAILINGSLASH = /\/$/;

export class ApiService {
  [key: string]: any; // (...args: any[]) => CancellableRequest;

  public readonly client: AxiosInstance;

  /**
   * Construct and return instance of ApiService that proxyfies all the methods calls those name starts from
   * standard http methods like 'get', 'post', 'delete' etc.
   *
   * @param config AxiosRequestConfig
   * @returns ApiService
   */
  public static createAxiosProxy(config: AxiosRequestConfig): ApiService {
    const apiService = new ApiService(config);
    return new Proxy(apiService, {
      get: (target: ApiService, propKey: string, receiver: any) => {
        const method:
          | typeof SUPPORTED_METHODS[number]
          | undefined = SUPPORTED_METHODS.find(m =>
          propKey.startsWith(m.toLowerCase())
        );

        if (!method && !!target[propKey]) {
          return Reflect.get(target, propKey, receiver);
        }
        if (!method) {
          throw new Error(
            `Unsupported method or property: ApiService.${propKey}().\nMethod should be prefixed (start from) one of standard http method like: ${SUPPORTED_METHODS.join(
              ', '
            )}`
          );
        }

        let path = propKey
          .substring(method.length)
          .replace(/([a-z])([A-Z])/g, '$1/$2') // adds slash between the words
          .toLowerCase();

        if (path !== '') {
          path = `/${path}`;
        }

        return (...args: any): CancellableRequest => {
          let p;
          // build url path from args
          if (args.length > 0) {
            const parts = [];
            p = args.shift();
            while (typeof p === 'string' || typeof p === 'number') {
              parts.push(
                // @ts-ignore
                String(p).replace(STARTINGSLASH, '').replace(TAILINGSLASH, '')
              );
              p = args.shift();
            }
            if (parts.length) {
              path = `${path}/${parts.join('/')}`;
            }
          }
          const queryOrBody = p || {};

          const cancelTokenSource = axios.CancelToken.source();
          const requestOptions: AxiosRequestConfig = {
            // @ts-ignore
            method,
            url: path,
            cancelToken: cancelTokenSource.token
          };

          if (['get', 'delete', 'head'].includes(method)) {
            requestOptions.params = queryOrBody;
          } else {
            requestOptions.data = queryOrBody;
          }

          const request = apiService.client
            .request(requestOptions)
            .then((response: AxiosResponse) => response.data)
            .catch(ApiService.handleErrorResponse);

          return {
            request,
            cancel: cancelTokenSource.cancel,
            then: (resolve?: () => {}, reject?: () => {}) =>
              request.then(resolve).catch(reject)
          } as CancellableRequest;
        };
      },

      has(target: any, propKey: string) {
        return SUPPORTED_METHODS.some(m => propKey.startsWith(m.toLowerCase()));
      }
    });
  }

  private constructor(config: AxiosRequestConfig = {}) {
    this.client = this.initializeClient(config);
  }

  private initializeClient(config: AxiosRequestConfig) {
    return axios.create({
      baseURL: getBaseUrl(),
      timeout: 30000,
      responseType: 'json',
      withCredentials: true,
      ...config
    });
  }

  private static handleErrorResponse(error: Error) {
    // @ts-ignore
    if (axios.isCancel(error) || error.message.__CANCEL__) {
      console.log('Request canceled', error.message);
    } else {
      console.log('Request Error', error);
    }
    throw error;
  }

  public setResponseInterceptor(
    onResponse?: (res: AxiosResponse) => AxiosResponse,
    onRequest?: (error: any) => any
  ) {
    return this.client.interceptors.response.use(onResponse, onRequest);
  }
}

export const apiService = ApiService.createAxiosProxy({});
export default apiService.client;
