import { useNavigate } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

const Icon = styled.i`
  padding-right: 10px;
`;

export const BackButton: React.FC = () => {
  const navigate = useNavigate();
  const onClick = e => {
    navigate(-1);
  };

  return (
    <Icon
      className={`nc-icon nc-minimal-left`}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    />
  );
};
