import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { PATH } from '../../../constants';
import Header from '../../../common/Navbar/Navbar';
import Sidebar from '../../../common/Sidebar/Sidebar';
import FixedPlugin from '../../../common/FixedPlugin/FixedPlugin';

interface Props {
  children: React.ReactElement;
}

const ContentWrapper = styled.div`
  margin: 20px;

  .card-body {
    padding: 15px 20px;
  }
`;

const BRAND = 'Devices Control - APM - 3200 Asset And Performance Manager';

const sidebarRoutes = [
  {
    layout: '',
    path: PATH.DASHBOARD,
    name: 'Dashboard',
    icon: 'spaceship'
  },
  {
    layout: '',
    path: PATH.IRPROTOCOLS,
    name: 'IR Protocols',
    icon: 'controller-modern'
  },
  {
    layout: '',
    path: PATH.CHRACTERISTICS,
    name: 'Coil Characteristics',
    icon: 'settings'
  }
];

const initialBackgroundColor = 'black';
const initialActiveColor = 'primary';

const AuthLayout: React.FC<Props> = ({ children }) => {
  let mainPanel;
  React.useRef(() => {
    mainPanel = React.createRef();
  });
  const location = useLocation();

  const [pageTitle, setPageTitle] = React.useState(BRAND);
  const [activeColor, setActiveColor] = React.useState(initialActiveColor);
  const [backgroundColor, seBackgroundColor] = React.useState(
    initialBackgroundColor
  );

  React.useEffect(() => {
    if (location.pathname === PATH.DASHBOARD) {
      setPageTitle(BRAND);
    } else {
      setPageTitle(`BMS-3000 - ${location.pathname.replace(/^\//, '')}`);
    }
  }, [location.pathname]);

  return (
    <div className="wrapper">
      <Sidebar
        routes={sidebarRoutes}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel" ref={mainPanel}>
        <Header routes={sidebarRoutes} brandName={pageTitle} />
        <ContentWrapper className="content">{children}</ContentWrapper>
      </div>
      <FixedPlugin
        bgColor={backgroundColor}
        activeColor={activeColor}
        handleActiveClick={setActiveColor}
        handleBgClick={seBackgroundColor}
      />
    </div>
  );
};

export default AuthLayout;
