import { store } from 'react-notifications-component';

interface NotificationOptions {
  title?: string;
  message: string;
  type?: string;
  insert?: 'top' | 'bottom';
  container?: string;
  dismiss?: any;
}

const defaults = {
  type: 'success',
  insert: 'top',
  container: 'top-center',
  dismiss: {
    duration: 5000,
    onScreen: true,
    pauseOnHover: true
  }
};

class Notification {
  show(options: NotificationOptions) {
    return store.addNotification({
      ...defaults,
      ...options
    });
  }

  success(options: NotificationOptions) {
    return this.show({
      ...options,
      type: 'success'
    });
  }

  info(options: NotificationOptions) {
    return this.show({
      ...options,
      type: 'info'
    });
  }

  warning(options: NotificationOptions) {
    return this.show({
      ...options,
      type: 'warning'
    });
  }

  error(options: NotificationOptions) {
    return this.show({
      ...options,
      type: 'danger'
    });
  }
}

export default new Notification();
