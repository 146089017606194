/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';
import { Container, Row } from 'reactstrap';
// used for making the prop types of this component
import PropTypes from 'prop-types';

interface FooterProps {
  default?: boolean;
  fluid?: boolean;
}

class Footer extends React.Component<FooterProps, any> {
  render() {
    return (
      <footer
        className={'footer' + (this.props.default ? ' footer-default' : '')}
      >
        <Container fluid={this.props.fluid}>
          <Row>
            <nav className="footer-nav">
              <ul></ul>
            </nav>
            <div className="credits ml-auto">
              <div className="copyright">
                &copy; {new Date().getFullYear()}, Nordatec Smart Energy
              </div>
            </div>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
