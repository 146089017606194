import styled from 'styled-components';
import { FormGroup, InputGroupText } from 'reactstrap';

export const InputWithAddonInline = styled.input`
  height: 40px;
  width: calc(100% - 36px);
`;

export const InputWithAddon = styled.input`
  height: 40px;
`;

export const InputPrepend = styled(InputGroupText)`
  font-size: smaller;
`;

export const FormGroupLine = styled(FormGroup)`
  margin-bottom: 0;
`;
