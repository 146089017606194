import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import {
  ACUnitCharacteristicType,
  ModalFormProps
} from '@app/modules/Characteristics/acunit.types';
import { apiService } from '@app/utils';
import {
  InputPrepend,
  InputWithAddonInline
} from '@app/modules/Characteristics/styles/forms';

export const CharacteristicsFormModal: React.FC<ModalFormProps<
  ACUnitCharacteristicType
>> = ({ formData, close, createNew = false }) => {
  const [isShown, setShown] = useState(true);
  // @ts-ignore
  const [modelData, updateForm] = useState<ACUnitCharacteristicType>({
    speed: 0,
    volumetricFlow: 0,
    fanPower: 0,
    ...formData
  });

  const params = useParams();

  const onChange = e => {
    const { name, value } = e.target;
    updateForm(form => ({
      ...form,
      [name]: value
    }));
  };

  const save = async () => {
    const { id, ...data } = modelData;
    if (createNew) {
      const row = await apiService.post(
        '/ac-units',
        data.acId,
        'characteristics',
        data
      );
      close(row);
    } else {
      const row = await apiService.put('/ac-units/characteristics', id, data);
      close(row);
    }
  };

  return (
    <Modal isOpen={isShown} backdrop={'static'} scrollable>
      <ModalHeader>Air Conditioner Unit</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Col md={4}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputPrepend>Speed</InputPrepend>
                </InputGroupAddon>
                <InputWithAddonInline
                  className="form-control"
                  type="number"
                  min={1}
                  max={3}
                  name="speed"
                  placeholder="Speed"
                  value={modelData.speed}
                  onChange={onChange}
                  required
                />
              </InputGroup>
            </Col>
            <Col md={4}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputPrepend>Vol.Flow</InputPrepend>
                </InputGroupAddon>
                <InputWithAddonInline
                  className="form-control"
                  type="number"
                  min={30}
                  name="volumetricFlow"
                  placeholder="VolumetricFlow"
                  value={modelData.volumetricFlow}
                  onChange={onChange}
                  required
                />
              </InputGroup>
            </Col>
            <Col md={4}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputPrepend>FanPower</InputPrepend>
                </InputGroupAddon>
                <InputWithAddonInline
                  className="form-control"
                  type="number"
                  min={10}
                  name="fanPower"
                  placeholder="FanPower"
                  value={modelData.fanPower}
                  onChange={onChange}
                  required
                />
              </InputGroup>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={save}>
          Save
        </Button>
        <Button color="secondary" onClick={() => close()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
