import React, { FormEvent } from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button, Container, Form, Input, Row, Col } from 'reactstrap';

import { ErrorBoundary } from '@app/common';
import Footer from '@app/common/Footer/Footer';
import { useAuthStore } from '../hooks';
import { PATH } from '@app/constants';

const Login: React.FC = () => {
  const { isUserLoggedIn, login, loading } = useAuthStore();

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  if (isUserLoggedIn) {
    return <Navigate to={PATH.DASHBOARD} />;
  }

  function postLogin(e: FormEvent) {
    e.preventDefault();
    login({
      username,
      password
    })
      .then(() => {
        setPassword('');
        setUsername('');
      })
      .catch(e => {
        console.error(e);
      });
  }

  return (
    <ErrorBoundary>
      <Container fluid className="mt-5">
        <Row>
          <Col
            sm={{ size: 6, offset: 3 }}
            md={{ size: 6, offset: 3 }}
            lg={{ size: 4, offset: 4 }}
            xl={{ size: 2, offset: 5 }}
          >
            <Form className="login-form mx-auto" onSubmit={postLogin}>
              <Input
                placeholder="Username"
                type="text"
                value={username}
                onChange={e => {
                  setUsername(e.target.value);
                }}
              />
              <Input
                placeholder="Password"
                type="password"
                value={password}
                onChange={e => {
                  setPassword(e.target.value);
                }}
              />
              <Button block color="primary" type="submit">
                Log in
              </Button>
            </Form>
          </Col>
        </Row>
        <Footer />
      </Container>
    </ErrorBoundary>
  );
};

export default observer(Login);
