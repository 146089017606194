import * as React from 'react';
import { observer } from 'mobx-react';
import {
  Row,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter
} from 'reactstrap';

interface CardPropsInterface {
  title: string;
}
const MyCard: React.FC<any> = ({ title, header, footer, children }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="float-left" tag="h5">
          {title}
        </CardTitle>
        <div className="float-right">{header}</div>
      </CardHeader>
      <CardBody>
        <Row>{children}</Row>
      </CardBody>
      {footer && <CardFooter>{footer}</CardFooter>}
    </Card>
  );
};

export const CardWrapper = observer(MyCard);
