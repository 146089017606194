/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav } from 'reactstrap';
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from 'perfect-scrollbar';

import logo from '@app/assets/images/logo-small.png';

interface SidebarProps {
  routes: any;
  bgColor: string;
  activeColor: string;
}
const Sidebar: React.FC<SidebarProps> = ({ bgColor, activeColor, routes }) => {
  const location = useLocation();

  let sidebar;
  useRef(() => {
    sidebar = React.createRef();
  });

  return (
    <div
      className="sidebar"
      data-color={bgColor}
      data-active-color={activeColor}
    >
      <div className="logo">
        <NavLink to="/" className="simple-text logo-mini">
          <div className="logo-img">
            <img src={logo} alt="react-logo" />
          </div>
        </NavLink>
        <NavLink to="/" className="simple-text logo-normal">
          NORDATEC
        </NavLink>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {routes.map((prop, key) => {
            return (
              <li
                className={
                  location.pathname === prop.path ? 'active' : '' //" active-pro"
                }
                key={key}
              >
                <NavLink
                  to={prop.layout + prop.path}
                  className="nav-link"
                  activeClassName="active"
                >
                  <i className={`nc-icon nc-${prop.icon}`} />
                  <p>{prop.name}</p>
                </NavLink>
              </li>
            );
          })}
          <li className="text-center bottom-line">
            <small>
              &copy; {new Date().getFullYear()} Nordatec Smart Energy
            </small>
          </li>
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
