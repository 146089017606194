import { action, observable, computed } from 'mobx';

import { UserInterface, LoginForm } from '../../types/auth';
import { apiService } from '../../utils/api';
import StorageService from '../../utils/storage';

const authTokenId = '_t';

export default class ModuleStore {
  private storage: StorageService;

  @observable public loading = true;
  @observable public userInfo: UserInterface | null = null;

  constructor() {
    this.storage = new StorageService('__');
    this.userInfo = this.storage.get(authTokenId);
  }

  @action.bound private setUser(userData) {
    this.userInfo = userData;
    console.log(this.userInfo?.login);
  }

  @computed get isUserLoggedIn(): boolean {
    return !!(this.userInfo && this.userInfo.login);
  }

  @action.bound private setLoading(status: boolean) {
    this.loading = status;
  }

  @action.bound public async checkUserAuth() {
    try {
      this.setLoading(true);
      const data: { user: UserInterface } = await apiService.getAuth();
      this.setUser(data.user);
    } finally {
      this.setLoading(false);
    }
  }

  @action.bound public async login(loginForm: LoginForm) {
    try {
      this.setLoading(true);
      const data: { user: UserInterface } = await apiService.postAuth(
        'login',
        loginForm
      );
      this.setUser(data.user);
      this.storage.set(authTokenId, this.userInfo);
    } finally {
      this.setLoading(false);
    }
  }

  @action.bound public removeToken() {
    this.userInfo = null;
    this.storage.remove(authTokenId);
  }

  @action.bound public async logout() {
    try {
      this.setLoading(true);
      await apiService.getAuth('logout');
      this.removeToken();
    } finally {
      this.setLoading(false);
    }
  }
}
