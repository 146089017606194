import { createGlobalStyle } from 'styled-components';

import { blackText, lightGray } from '@app/styles/colors';

export const GlobalStyle = createGlobalStyle`
  body {
    color: ${blackText};
    background-color: ${lightGray};
    font-size: 14px;
    font-weight: 400
  }
`;
