import React from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';

const Icon = styled.i`
  padding-right: 10px;
`;

interface AddButtonPropInterface {
  onClick: (e) => void;
  label?: string;
}

export const AddButton: React.FC<AddButtonPropInterface> = ({
  onClick,
  label = 'Add new'
}) => {
  return (
    <Button color="success" onClick={onClick}>
      <Icon className={`nc-icon nc-simple-add`} style={{ cursor: 'pointer' }} />
      {label}
    </Button>
  );
};
