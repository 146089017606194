import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { apiService, notifications } from '@app/utils';
import { PATH } from '@app/constants';
import { useAuthStore } from '../hooks';

interface Props {
  children: React.ReactElement;
}

const Auth: React.FC<Props> = ({ children }) => {
  const {
    loading,
    checkUserAuth,
    isUserLoggedIn,
    removeToken
  } = useAuthStore();
  const navigate = useNavigate();

  apiService.setResponseInterceptor(
    response => response,
    error => {
      const { response } = error;
      if (response && response.status === 401) {
        const data = response;
        removeToken();
        notifications.warning({
          title: data.error || data.message,
          message: 'Your session has been expired',
          container: 'bottom-right'
        });
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    checkUserAuth();
  }, []);

  // if user unauthorized -> redirect to login
  useEffect(() => {
    if (!loading && !isUserLoggedIn) {
      navigate(PATH.LOGIN);
    }
  }, [loading, isUserLoggedIn]);

  if (loading) {
    return <h2>Checking user...</h2>;
  }

  return children;
};

export default observer(Auth);
