import { action, observable, IObservableArray } from 'mobx';
import { apiService } from '../../utils';
import { ACUnitType } from '@app/modules/Characteristics/acunit.types';

export class CoilCharacteristicsStore {
  @observable public acUnitsList: IObservableArray<
    ACUnitType
  > = observable.array([]);
  @observable public acUnitCharacteristicsMap = {};

  @action.bound public async fetchACUnitsList() {
    this.acUnitsList = await apiService.get('/ac-units');
  }

  @action.bound public async fetchACUnitCharacteristics(id, force = false) {
    this.acUnitCharacteristicsMap[id] = await apiService.get(
      `/ac-units/${id}/characteristics`
    );
    return this.acUnitCharacteristicsMap[id];
  }

  @action.bound public async addACUnit(model) {
    this.acUnitsList.push(model);
  }

  @action.bound public async updateACUnit(model) {
    if (this.acUnitsList.length) {
      const unit = this.acUnitsList.find(unit => unit.id === model.id);
      Object.assign(unit, model);
    }
  }

  @action.bound public async removeACUnit(model) {
    return this.acUnitsList.remove(model);
  }

  @action.bound public addACUnitCharacteristicRow(unitId, row) {
    if (this.acUnitCharacteristicsMap[unitId].length) {
      const index = this.acUnitCharacteristicsMap[unitId].findIndex(
        c => c.speed > row.speed
      );
      if (index > -1) {
        this.acUnitCharacteristicsMap[unitId].splice(
          index === 0 ? index - 1 : index,
          0,
          row
        );
      } else {
        this.acUnitCharacteristicsMap[unitId].push(row);
      }
    } else {
      this.acUnitCharacteristicsMap[unitId].push(row);
    }
  }

  @action.bound public removeACUnitCharacteristicRow(unitId, row) {
    this.acUnitCharacteristicsMap[unitId].remove(row);
  }

  @action.bound public updateACUnitCharacteristicRow(unitId, row) {
    const characteristic = this.acUnitCharacteristicsMap[unitId].find(
      c => c.id === row.id
    );
    Object.assign(characteristic, row);
  }

  public resolveACunitById(id) {
    return this.acUnitsList.find(unit => unit.id === id);
  }

  public getCharacteristics(id) {
    return this.acUnitCharacteristicsMap[id];
  }
}
