import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';

import { StoreProvider } from './state/Context';
import { Loadable } from './common';
import { Auth, Login } from './modules/Auth';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/paper-dashboard.scss';
import 'react-notifications-component/dist/scss/notification.scss';
import './assets/demo.css';

import { GlobalStyle } from './styles/global';
import AuthLayout from '@app/modules/Auth/components/AuthLayout';

const App = Loadable(() => import('./App'));

ReactDOM.render(
  <BrowserRouter>
    <StoreProvider>
      <Routes>
        <Route
          path="/login"
          element={
            <>
              <ReactNotification />
              <Login />
            </>
          }
        />
        <Route
          path="*"
          element={
            <>
              <ReactNotification />
              <Auth>
                <AuthLayout>
                  <App />
                </AuthLayout>
              </Auth>
            </>
          }
        />
      </Routes>
    </StoreProvider>
    <GlobalStyle />
  </BrowserRouter>,
  document.getElementById('root')
);
