/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container
} from 'reactstrap';
import { useAuthStore } from '@app/modules/Auth/hooks';

interface HeaderProps {
  routes: any;
  brandName?: string;
  layout?: string;
  name?: string;
  path?: string;
}

const COLORS = {
  TRANSPARENT: 'transparent',
  DARK: 'dark'
};

const MIN_WINDOW_WIGTH = 993;

const Header: React.FC<HeaderProps> = ({
  routes,
  brandName,
  layout = '',
  name = '',
  path = ''
}) => {
  const { logout } = useAuthStore();
  let sidebarToggle = React.createRef<HTMLButtonElement>();
  useRef(() => {
    sidebarToggle = React.createRef<HTMLButtonElement>();
  });
  const [color, setColor] = useState(COLORS.TRANSPARENT);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => {
    if (isOpen) {
      setColor(COLORS.TRANSPARENT);
    } else {
      setColor(COLORS.DARK);
    }
    setIsOpen(!isOpen);
  };

  const dropdownToggle = e => {
    setDropdownOpen(!dropdownOpen);
  };

  const getBrand = () => {
    routes.map((prop, key) => {
      if (window.location.href.indexOf(layout + path) !== -1) {
        brandName += ` ${name}`;
      }
      return null;
    });
    return brandName?.toUpperCase();
  };

  const openSidebar = e => {
    document.documentElement.classList.toggle('nav-open');
    // @ts-ignore
    if (sidebarToggle) {
      // @ts-ignore
      sidebarToggle.current.classList.toggle('toggled');
    }
  };

  useLayoutEffect(() => {
    // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
    const updateColor = () => {
      if (window.innerWidth < MIN_WINDOW_WIGTH && isOpen) {
        setColor(COLORS.DARK);
      } else {
        setColor(COLORS.TRANSPARENT);
      }
    };
    window.addEventListener('resize', updateColor);
    return () => window.removeEventListener('resize', updateColor);
  }, []);

  useEffect(() => {
    if (
      window.innerWidth < MIN_WINDOW_WIGTH &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
      // @ts-ignore
      sidebarToggle.current.classList.toggle('toggled');
    }
  });

  // add or remove classes depending if we are on full-screen-maps page or not
  return (
    <Navbar
      color={color}
      expand="lg"
      className={
        // this.props.location.pathname.indexOf("full-screen-maps") !== -1
        (true ? 'navbar-absolute ' : 'fixed-top ') +
        (color === COLORS.TRANSPARENT ? 'navbar-transparent ' : '')
      }
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={openSidebar}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand href="/">{getBrand()}</NavbarBrand>
        </div>
        <NavbarToggler onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          {/* <form> */}
          {/*  <InputGroup className="no-border"> */}
          {/*    <Input placeholder="Search..." /> */}
          {/*    <InputGroupAddon addonType="append"> */}
          {/*      <InputGroupText> */}
          {/*        <i className="nc-icon nc-zoom-split" /> */}
          {/*      </InputGroupText> */}
          {/*    </InputGroupAddon> */}
          {/*  </InputGroup> */}
          {/* </form> */}
          <Nav navbar>
            <NavItem>
              <Link to="/dashboard" className="nav-link btn-magnify">
                <i className="nc-icon nc-layout-11" />
                <p>
                  <span className="d-lg-none d-md-block">Dashboard</span>
                </p>
              </Link>
            </NavItem>
            <Dropdown nav isOpen={dropdownOpen} toggle={dropdownToggle}>
              <DropdownToggle caret nav>
                <i className="nc-icon nc-single-02" />
                {/* <Avatar icon={<UserOutlined />} /> */}
                <p>
                  <span className="d-lg-none d-md-block">Account</span>
                </p>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag="a" onClick={logout}>
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            {/*<NavItem>*/}
            {/*  <Link to='#settings' className='nav-link btn-rotate'>*/}
            {/*    <i className='nc-icon nc-settings-gear-65' />*/}
            {/*    <p>*/}
            {/*      <span className='d-lg-none d-md-block'>*/}
            {/*        Settings*/}
            {/*      </span>*/}
            {/*    </p>*/}
            {/*  </Link>*/}
            {/*</NavItem>*/}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
