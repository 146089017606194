import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, ButtonGroup, Table } from 'reactstrap';
import { CardWrapper } from '@app/common/Card/CardWrapper';
import { useStores } from '@app/hooks/stores';
import { ACUnitCharacteristicType } from '@app/modules/Characteristics/acunit.types';
import { CharacteristicsFormModal } from './CharacteristicsFormModal';
import { apiService } from '@app/utils';
import { BackButton } from '@app/common/Buttons/BackButton';
import { AddButton } from '@app/common/Buttons/AddButton';

export const Characteristics: React.FC<any> = ({}) => {
  const { coilCharacteristicsStore } = useStores();
  const { id: idParam } = useParams();
  const id = Number(idParam);
  const unit = coilCharacteristicsStore.resolveACunitById(id);

  const defaultRow = {
    acId: id,
    speed: 1,
    volumetricFlow: 0,
    fanPower: 0
  };
  const [modal, setOpenModal] = useState(false);
  const [modalForm, setModalForm] = useState<ACUnitCharacteristicType>({
    ...defaultRow
  });
  const [isCreateNew, setCreateNew] = useState(false);

  // @fixme
  // useEffect(() => {
  //   if (!unit) coilCharacteristicsStore.fetchACUnitsList();
  // }, [id]);

  const openForm = row => (e?) => {
    e && e.preventDefault();
    setModalForm(row);
    setOpenModal(true);
  };

  const onCreateNew = e => {
    setCreateNew(true);
    const speed = coilCharacteristicsStore.acUnitCharacteristicsMap[id]
      ? Math.max(
          ...coilCharacteristicsStore.acUnitCharacteristicsMap[id].map(
            c => c.speed
          )
        ) + 1
      : 1;
    openForm({ ...defaultRow, speed: speed > 0 ? speed : 1 })(e);
  };

  const onClose = (updatedRow?: any) => {
    if (updatedRow) {
      if (isCreateNew) {
        coilCharacteristicsStore.addACUnitCharacteristicRow(id, updatedRow);
      } else {
        coilCharacteristicsStore.updateACUnitCharacteristicRow(id, updatedRow);
      }
    }
    setOpenModal(false);
    setCreateNew(false);
  };

  const removeRow = row => (e?) => {
    e.stopPropagation();
    const yes = window.confirm(
      `Do you really want to remove this row ?\n${JSON.stringify(row)}`
    );
    if (yes) {
      apiService.delete('/ac-units', 'characteristics', row.id).then(
        () => {},
        () => {
          coilCharacteristicsStore.addACUnitCharacteristicRow(id, row);
        }
      );
      coilCharacteristicsStore.removeACUnitCharacteristicRow(id, row);
    }
  };

  return (
    <CardWrapper
      title={
        <p>
          <BackButton />
          {unit && (
            <i>
              {unit.make} {unit.model}
            </i>
          )}{' '}
          Coil Characteristics
        </p>
      }
      header={<AddButton onClick={onCreateNew} />}
    >
      <p className="card-category"></p>
      <Table hover>
        <thead className="text-primary">
          <tr>
            <th className="text-center">Speed</th>
            <th className="text-center">Volumetric Flow (L/s)</th>
            <th className="text-center">Fan Power (W)</th>
            <th className="text-center"></th>
          </tr>
        </thead>
        <tbody>
          {coilCharacteristicsStore.acUnitCharacteristicsMap[id] &&
            coilCharacteristicsStore.acUnitCharacteristicsMap[id].map(
              (row, index) => (
                <tr
                  key={index}
                  onClick={openForm(row)}
                  style={{ cursor: 'pointer' }}
                >
                  <td className="text-center">{row.speed}</td>
                  <td className="text-center">{row.volumetricFlow}</td>
                  <td className="text-center">{row.fanPower}</td>
                  <td className="text-right">
                    <ButtonGroup size="sm">
                      <Button onClick={openForm(row)} color="primary">
                        Edit
                      </Button>
                      <Button onClick={removeRow(row)} color="danger">
                        Remove
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              )
            )}
        </tbody>
      </Table>
      {modal && (
        <CharacteristicsFormModal
          isOpen={modal}
          formData={modalForm}
          createNew={isCreateNew}
          close={onClose}
        />
      )}
    </CardWrapper>
  );
};
